import React from "react";
import classnames from "classnames";

import Button from "lib/@cms/components/primitive/Button";
import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";

import Styles from "./Feature.module.css";

const VARIANTS = {
  V1: "V1",
  V2: "V2",
};

function Feature({ variant, ...props }) {
  if (!props.data) return null;

  if (variant === VARIANTS.V1) {
    return <FeatureV1 {...props} />;
  }

  if (variant === VARIANTS.V2) {
    return <FeatureV2 {...props} />;
  }

  throw new Error(`Invalid Feature variant: ${variant}`);
}

Feature.variant = VARIANTS;

export default withErrorBoundaryHOC(Feature);

// --- Components ---

function FeatureV1({ data }) {
  return (
    <div className="tw-pt-24 tw-pb-24 bl-bg-primary-6 tw-rounded-xl">
      <div className="tw-mx-auto tw-text-center tw-w-10/12 lg:tw-w-8/12">
        <h2 className="bl-font-secondary tw-font-semibold bl-text-primary bl-text-2xl lg:bl-text-3xl tw-leading-tight">
          {data.title}
        </h2>
        <div className="tw-mt-8 tw-mb-12 lg:tw-my-12 tw-pb-2 bl-text-grayscale bl-text-lg">
          <p className="tw-leading-tight">{data.description}</p>
        </div>
        <Button is="a" href={data.buttonUrl}>
          {data.buttonText}
        </Button>
      </div>
    </div>
  );
}

function FeatureV2({ data }) {
  return (
    <div
      className={classnames(
        "lg:tw-flex lg:tw-flex-row lg:tw-justify-between lg:tw-flex-no-wrap",
        data.imgAlign === "left" && "lg:tw-flex-row-reverse",
      )}
    >
      {data.image && (
        <img
          src={data.image.url}
          alt={data.title}
          className={classnames(
            "tw-rounded-lg md:tw-mx-auto tw-max-w-full tw-max-h-full tw-object-cover",
            Styles.featureV2Image,
            data.imgAlign === "left" ? "lg:tw-ml-8 lg:tw-mr-0" : "lg:tw-mr-8 lg:tw-ml-0",
          )}
        />
      )}
      <div className="tw-mt-6 lg:tw-mt-0  lg:tw-w-6/12 md:tw-text-center lg:tw-text-left tw-relative">
        <h3 className="bl-font-secondary tw-font-bold bl-text-primary-180 bl-text-2xl tw-mb-5 tw-leading-snug">
          {data.title}
        </h3>
        <p className="bl-text-lg tw-leading-snug">{data.description}</p>
        {data.descriptions && (
          <ul className="bl-text-lg lg:tw-h-48">
            {data.descriptions.map((description, index) => {
              return <li key={`FeatureV2-description-${index}`}>{description.text}</li>;
            })}
          </ul>
        )}
        <div className="bl-text-xs tw-mt-12 lg:tw-mt-0 lg:tw-absolute tw-bottom-0 tw-mb-7">
          {data.caption && <p className="tw-italic bl-text-grayscale-80 tw-my-5">{data.caption}</p>}
          {data.buttonText && (
            <Button is="a" href={data.buttonUrl} target="_blank">
              {data.buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
