import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Feature from "lib/@cms/components/cms/Feature";
import Callout from "lib/@cms/components/cms/Callout";
import ContentBox from "lib/@cms/components/shared/ContentBox";
import Divider from "lib/@cms/components/primitive/Divider";

function AboutUsPage() {
  return (
    <Page pathname="about-us">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENT}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradient}
            />
            <Divider className="tw-my-7" />

            <div className="tw-container">
              <ContentBox>
                {data.TextBodyA?.map((element, index) => {
                  return <p key={`TextBodyA-${index}`}>{element.item}</p>;
                })}
              </ContentBox>
              <Divider className="tw-my-7" />

              <ContentBox title={data.TextBodyList.title}>
                <ul className="tw-list-disc tw-ml-6">
                  {data.TextBodyList.list?.map((element, index) => {
                    return <li key={`TextBodyList-${index}`}>{element.item}</li>;
                  })}
                </ul>
              </ContentBox>
              <Divider className="tw-my-7 sm:tw-my-14" />

              <Feature variant={Feature.variant.V1} data={data.FeatureV1} />
              <Divider className="tw-my-7 sm:tw-my-14" />

              <ContentBox title={data.TextBodyB.title}>{data.TextBodyB.description}</ContentBox>
              <Divider className="tw-my-7 sm:tw-my-10" />

              <Callout variant={Callout.variant.BUTTON} data={data.CalloutButton} />
              <Divider className="tw-my-7" />
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default AboutUsPage;
