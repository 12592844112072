import React from "react";
import classnames from "classnames";

import ContainerGradient from "lib/@cms/components/shared/ContainerGradient";
import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";

import Styles from "./PageHeader.module.css";

const VARIANTS = {
  GRADIENT: "GRADIENT",
  GRADIENTS: "GRADIENTS",
};

const COLORS = {
  PRIMARY: "PRIMARY",
  GRAYSCALE: "GRAYSCALE",
};

function PageHeader({ variant = "GRADIENTS", ...props }) {
  if (variant === VARIANTS.GRADIENT) {
    return <Gradient {...props} />;
  }

  if (variant === VARIANTS.GRADIENTS) {
    return <Gradients {...props} />;
  }

  throw new Error(`Invalid PageHeader variant: ${variant}`);
}

PageHeader.variant = VARIANTS;
PageHeader.color = COLORS;

export default withErrorBoundaryHOC(PageHeader);

// --- Components ---

// Banner and title
function Gradient({ color, data }) {
  return (
    <div className="tw-relative tw-pb-6">
      {data.image && <ContainerGradient className="tw-h-96" image={data.image.url} />}
      <div className="tw-absolute tw-w-full tw-bottom-0">
        <Title color={color}>{data.title}</Title>
      </div>
    </div>
  );
}

// Only title
function Gradients({ color, data }) {
  return (
    <div className="tw-relative tw-h-80 lg:tw-h-56">
      <div className={classnames("tw-h-80 lg:tw-h-56", Styles.gradient)} />
      <div className="tw-absolute tw-w-full tw-bottom-0 tw-py-6">
        <Title color={color}>{data.title}</Title>
      </div>
    </div>
  );
}

function Title({ children, color = "PRIMARY" }) {
  return (
    <div className="tw-container">
      <h1
        className={classnames(
          "bl-font-secondary tw-font-semibold bl-text-2xl md:bl-text-3xl tw-leading-tight md:tw-leading-normal",
          color === COLORS.PRIMARY ? "bl-text-primary" : "bl-text-grayscale",
        )}
      >
        {children}
      </h1>
    </div>
  );
}
