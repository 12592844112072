import React from "react";

import Button from "lib/@cms/components/primitive/Button";
import withErrorBoundaryHOC from "lib/@cms/hocs/withErrorBoundary";

const VARIANTS = {
  BUTTON: "BUTTON",
  MOBILE_APPS: "MOBILE_APPS",
  DATA: "DATA",
  TEXT: "TEXT",
};

function Callout({ variant, ...props }) {
  if (variant === VARIANTS.MOBILE_APPS) {
    return <CalloutMobileApps {...props} />;
  }

  if (variant === VARIANTS.BUTTON) {
    return <CalloutButton {...props} />;
  }

  if (variant === VARIANTS.DATA) {
    return <CalloutData {...props} />;
  }

  if (variant === VARIANTS.TEXT) {
    return <CalloutText {...props} />;
  }

  throw new Error(`Invalid Callout variant: ${variant}`);
}

Callout.variant = VARIANTS;

export default withErrorBoundaryHOC(Callout);

// --- Components ---

function CalloutMobileApps({ data }) {
  return (
    <div className="tw-rounded-lg bl-bg-primary-6 tw-flex tw-flex-wrap tw-justify-center md:tw-justify-between tw-items-center tw-p-16 lg:tw-p-20">
      <div className="tw-w-64 tw-text-center md:tw-text-left">
        <h3 className="bl-font-secondary tw-font-semibold bl-text-primary-180 bl-text-xl tw-mb-3">
          {data.title}
        </h3>
        <p className="bl-text-grayscale-100">{data.description}</p>
      </div>
      <div className="tw-flex tw-mt-8">
        <a href={data.mobileApp1} target="_blank" rel="noreferrer">
          <img
            className="tw-w-auto tw-h-8 sm:tw-h-10 tw-object-cover tw-rounded-md"
            src={data.mobileAppImage1}
            alt="Google play store"
          />
        </a>
        <a href={data.mobileApp2} target="_blank" rel="noreferrer" className="tw-ml-2 lg:tw-ml-8">
          <img
            src={data.mobileAppImage2}
            className="tw-w-auto tw-h-8 sm:tw-h-10 tw-object-cover tw-rounded-md"
            alt="Apple store"
          />
        </a>
      </div>
    </div>
  );
}

function CalloutData({ title, description, items }) {
  return (
    <div className="bl-bg-primary-6 tw-text-center md:tw-text-left tw-rounded-lg">
      <div className="tw-card lg:tw-flex sm:tw-justify-between tw-flex-wrap tw-px-8 xl:tw-px-24 tw-py-10">
        <div className="lg:tw-w-7/12 tw-self-center">
          <div className="sm:tw-w-full md:tw-w-full sm:tw-text-center md:tw-text-left">
            <h3 className="bl-font-secondary tw-font-bold bl-text-primary-180 bl-text-xl tw-pb-3">
              {title}
            </h3>
            <p className="bl-text-md tw-mb-8">{description}</p>
          </div>
        </div>
        <div className="lg:tw-w-4/12 tw-self-center tw-px-2 tw-py-2">
          <ul>
            {items.map((item, i) => (
              <li key={`CalloutData-item-${i}`}>
                <span className="bl-font-secondary tw-font-bold tw-pr-3 bl-text-primary tw-font-black">
                  {item.name}
                </span>{" "}
                <span>{item.content}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

function CalloutButton({ data }) {
  return (
    <div className="bl-bg-primary-6 tw-p-10 tw-rounded-xl">
      <h3 className="bl-font-secondary bl-font-medium bl-text-grayscale bl-text-xl tw-mb-5">
        {data.title}
      </h3>
      <p className="tw-mb-8 sm:bl-text-lg">{data.description}</p>
      <Button is="a" href={data.href}>
        {data.button}
      </Button>
    </div>
  );
}

function CalloutText({ title, list }) {
  return (
    <div className="tw-pl-8 tw-border-l-4 bl-border-primary">
      <h4 className="tw-font-semibold bl-text-xl sm:bl-text-xl lg:bl-text-xl tw-leading-tight tw-mb-6">
        <span className="bl-text-primary">{title}</span>
      </h4>
      <ul className="tw-list-disc bl-text-md tw-ml-4">
        {list.map((text, index) => {
          return (
            <li key={`CalloutText-text-${index}`} className="tw-my-2 last:tw-my-0">
              {text.item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
